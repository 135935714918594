import React from 'react'

class Canvas extends React.Component{
  constructor(props) {
    super(props)
    this.state={
      showpreloader:true
    }
  }

  componentDidMount() {
  this.setState({showpreloader:false})






  }

  render() {
    return (
        <div className="canvas" style={{display:'none'}} id='canvas'>
            <div id='box_wrapper'>
              {this.state.showpreloader? <div className="preloader">
                <div className="preloader_image"></div>
              </div> : null}
              <div>{this.props.children}</div>

            </div>
        </div>
    )
  }

}

export default Canvas;
