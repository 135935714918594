import React from 'react'
import {Link} from 'gatsby'


class Breadcrumb extends React.PureComponent {
  constructor(props){
    super(props)
  }

  render() {
    if(this.props.path && this.props.title) {

      return (
        <section className="page_title ls s-py-50 corner-title ls invise overflow-visible breadcrumb-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1 className="breadcrumb-title">{this.props.title}</h1>
                <ol className="breadcrumb">
                  {this.props.path.map((item,i) => {
                    return (

                      <li key={i} className={(this.props.path.length === Number(i+1)) ? "breadcrumb-item active" : "breadcrumb-item"}>

                        {(this.props.path.length === Number(i+1)) ? <span>{item.value}</span> :  <Link to={item.path+'/'}>{item.value}</Link>}


                      </li>
                    )
                  })}

                </ol>
                <div className="divider-15 d-none d-xl-block"></div>
              </div>
            </div>
          </div>
        </section>
      )
    }
    else {
      return (
        <section className="page_title ls s-py-50 corner-title ls invise overflow-visible">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1>Single Service 3</h1>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="./">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">Pages</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Single Service 3
                  </li>
                </ol>
                <div className="divider-15 d-none d-xl-block"></div>
              </div>
            </div>
          </div>
        </section>
      )
    }

  }
}

export default Breadcrumb
