import React from 'react'
import {StaticQuery, graphql, Link, navigate} from 'gatsby'
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css'




class Header extends React.PureComponent {
     constructor(props) {
       super(props)


     }
  componentDidMount() {

    // if(window.history.state) {
    //   if(window.history.state.countrycode) {
    //      this.refs.userFlag.updateSelected(window.history.state.countrycode)
    //      this.refs.userFlag2.updateSelected(window.history.state.countrycode)
    //
    //   }
    //
    // }
    var $ = window.$
     var flag = $('.selected--flag--option  .country-flag')
     flag.before(`<i class="fas fa-globe"></i>`)

    function initMegaMenu(timeOut) {
    	var $megaMenu = $('.top-nav .mega-menu');
    	if($megaMenu.length) {
    		setTimeout(function () {

    			var windowWidth = $('body').innerWidth();
    			if (windowWidth > 991) {
    				$megaMenu.each(function(){
    					var $thisMegaMenu = $(this);
    					//temporary showing mega menu to proper size calc
    					$thisMegaMenu.css({'display': 'block', 'left': 'auto'});

    					//checking for sticked side header
    					var stickedSideHeaderWidth = 0;
    					var $stickedSideHeader = $('.header_side_sticked');
    					if($stickedSideHeader.length && $stickedSideHeader.hasClass('active-slide-side-header')) {
    						stickedSideHeaderWidth = $stickedSideHeader.outerWidth(true);
    						if($stickedSideHeader.hasClass('header_side_right')) {
    							stickedSideHeaderWidth = -stickedSideHeaderWidth;
    						}
    						windowWidth = windowWidth - stickedSideHeaderWidth;
    					}
    					var thisWidth = $thisMegaMenu.outerWidth();
    					var thisOffset = $thisMegaMenu.offset().left - stickedSideHeaderWidth;
    					var thisLeft = (thisOffset + (thisWidth/2)) - windowWidth/2;
    					$thisMegaMenu.css({'left' : -thisLeft, 'display': 'none'});
    					if(!$thisMegaMenu.closest('ul').hasClass('nav')) {
    						$thisMegaMenu.css('left', '');
    					}
    				});
    			}
    		}, timeOut);

    	}
    }
    function menuHideExtraElements() {

    	//cleaneng changed elements
    	$('.sf-more-li, .sf-logo-li').remove();
    	var windowWidth = $('body').innerWidth();

    	$('.sf-menu').each(function(){
    		var $thisMenu = $(this);
    		var $menuWraper = $thisMenu.closest('.top-nav');
    		$menuWraper.attr('style', '');
    		if (windowWidth > 1199) {
    			//grab all main menu first level items
    			var $menuLis = $menuWraper.find('.sf-menu > li');
    			$menuLis.removeClass('sf-xl-hidden');

    			var $headerLogoCenter = $thisMenu.closest('.header_logo_center');
    			var logoWidth = 0;
    			var summaryLiWidth = 0;

    			if ( $headerLogoCenter.length ) {
    				var $logo = $headerLogoCenter.find('.logo');
    				// 30/2 - left and right margins
    				logoWidth = $logo.outerWidth(true) + 70;
    			}

    			// var wrapperWidth = $('.sf-menu').width();
    			var wrapperWidth = $menuWraper.outerWidth(true);
    			$menuLis.each(function(index) {
    				//4 - 4px additional width for inline-block LI element
    				var elementWidth = $(this).outerWidth() +4;
    				summaryLiWidth += elementWidth;
    				if(summaryLiWidth >= (wrapperWidth-logoWidth)) {
    					var $newLi = $('<li class="sf-more-li"><a>...</a><ul></ul></li>');
    					$($menuLis[index - 1 ]).before($newLi);
    					var newLiWidth = $($newLi).outerWidth(true);
    					var $extraLiElements = $menuLis.filter(':gt('+ ( index - 2 ) +')');
    					$extraLiElements.clone().appendTo($newLi.find('ul'));
    					$extraLiElements.addClass('sf-xl-hidden');
    					return false;
    				}
    			});

    			//processing center logo
    			if ( $headerLogoCenter.length ) {
    				var $menuLisVisible = $headerLogoCenter.find('.sf-menu > li:not(.sf-xl-hidden)');
    				var menuLength = $menuLisVisible.length;
    				var summaryLiVisibleWidth = 0;
    				$menuLisVisible.each(function(){
    					summaryLiVisibleWidth += $(this).outerWidth();
    				});

    				var centerLi = Math.floor( menuLength / 2 );
    				if ( (menuLength % 2 === 0) ) {
    					centerLi--;
    				}
    				var $liLeftFromLogo = $menuLisVisible.eq(centerLi);
    				$liLeftFromLogo.after('<li class="sf-logo-li"><a href="#">&nbsp;</a></li>');
    				$headerLogoCenter.find('.sf-logo-li').width(logoWidth);
    				var liLeftRightDotX = $liLeftFromLogo.offset().left + $liLeftFromLogo.outerWidth();
    				var logoLeftDotX = windowWidth/2 - logoWidth/2;
    				var menuLeftOffset = liLeftRightDotX - logoLeftDotX;
    				$menuWraper.css({'left': -menuLeftOffset})
    			}

    		}// > 991
    	}); //sf-menu each
    } //menuHideExtraElements

    var $header = $('.page_header').first();
  	var boxed = $header.closest('.boxed').length;
  	var headerSticked = $('.header_side_sticked').length;
  	if ($header.length) {
  		//hiding main menu 1st levele elements that do not fit width
  		menuHideExtraElements();
  		//mega menu
  		initMegaMenu(1);
  		//wrap header for smooth stick and unstick
  		var headerHeight = $header.outerHeight();
  		$header.wrap('<div class="page_header_wrapper"></div>');
  		var $headerWrapper = $('.page_header_wrapper');
  		if (!boxed) {
  			$headerWrapper.css({height: headerHeight});
  		}

  		//headerWrapper background - same as header
  		if( $header.hasClass('ls') ) {
  			$headerWrapper.addClass('ls');
  			if ( $header.hasClass('ms') ) {
  				$headerWrapper.addClass('ms');
  			}
  		} else if ( $header.hasClass('ds') ) {
  			$headerWrapper.addClass('ds');
  			if ( $header.hasClass('bs') ) {
  				$headerWrapper.addClass('bs');
  			}
  			if ( $header.hasClass('ms') ) {
  				$headerWrapper.addClass('ms');
  			}

  		} else if ( $header.hasClass('cs') ) {
  			$headerWrapper.addClass('cs');
  			if ( $header.hasClass('cs2') ) {
  				$headerWrapper.addClass('cs2');
  			}
  			if ( $header.hasClass('cs3') ) {
  				$headerWrapper.addClass('cs3');
  			}
  		} else if ( $header.hasClass('gradient-background') ) {
  			$headerWrapper.addClass('gradient-background');
  		}

  		//get offset
  		var headerOffset = 0;
  		//check for sticked template headers
  		if (!boxed && !($headerWrapper.css('position') === 'fixed')) {
  			headerOffset = $header.offset().top;
  		}

  		//for boxed layout - show or hide main menu elements if width has been changed on affix
  		$header.on('affixed-top.bs.affix affixed.bs.affix affixed-bottom.bs.affix', function ( e ) {
  			if( $header.hasClass('affix-top') ) {
  				$headerWrapper.removeClass('affix-wrapper affix-bottom-wrapper').addClass('affix-top-wrapper');
  				//cs to ls when affixed
  				// if($header.hasClass('cs')) {
  				// 	$header.removeClass('ls');
  				// }
  			} else if ( $header.hasClass('affix') ) {
  				$headerWrapper.removeClass('affix-top-wrapper affix-bottom-wrapper').addClass('affix-wrapper');
  				//cs to ls when affixed
  				// if($header.hasClass('cs')) {
  				// 	$header.addClass('ls');
  				// }
  			} else if ( $header.hasClass('affix-bottom') ) {
  				$headerWrapper.removeClass('affix-wrapper affix-top-wrapper').addClass('affix-bottom-wrapper');
  			} else {
  				$headerWrapper.removeClass('affix-wrapper affix-top-wrapper affix-bottom-wrapper');
  			}

  			//calling this functions disable menu items animation when going from affix to affix-top with centered logo inside
  			//in boxed layouts header is always fixed
  			if (boxed && !($header.css('position') === 'fixed')) {
  				menuHideExtraElements();
  				initMegaMenu(1);
  			}
  			if(headerSticked) {
  				initMegaMenu(1);
  			}

  		});

  		//if header has different height on afixed and affixed-top positions - correcting wrapper height
  		$header.on('affixed-top.bs.affix', function () {
  			// $headerWrapper.css({height: $header.outerHeight()});
  		});

  		//fixing auto affix bug - toggle affix on click when page is at the top
  		$header.on('affix.bs.affix', function(){
  			if( !$(window).scrollTop() ) return false;
  		});

  		$header.affix({
  			offset: {
  				top: headerOffset,
  				bottom: -10
  			}
  		});
  	}

}
componentWillUnmount() {
  console.log('header unmounting')

}

onSelectFlag = (countrycode) => {
  let country=''
  if(this.props.langpath) {
    if(countrycode === 'GB') {
      country = 'en'

    }
    else {
      country = 'el'

    }
    this.props.langpath.forEach((item) => {
      if(item.id === country) {
           navigate(item.url)
      }
    })
  }

}

handleClick = (e) => {

  var $ = window.$
   $('.page_header').toggleClass('mobile-active')
  $(this)
    .toggleClass('mobile-active')
    .closest('.page_header')
    .toggleClass('mobile-active')
    .end()
    .closest('.page_toplogo')
    .next()
    .find('.page_header')
    .toggleClass('mobile-active');

}

  render() {
       console.log('Header renders')
    return (










                   <div className={this.props.ishome? "header_absolute s-pb-30": null}>
                 <header className="page_header ds justify-nav-end">
                   <div className="container-fluid">
                     <div className="row align-items-center">
                       <div className="col-xl-2 col-lg-3 col-11">
                         {this.props.language === 'EN'? <Link className="logo text-center" to='/en'>
                         <img src={this.props.data.drupal.headerlogo.fieldImage.imageFile.publicURL} alt="logo"/>
                         </Link> : <Link className="logo text-center" to='/el'>
                         <img src={this.props.data.drupal.headerlogo.fieldImage.imageFile.publicURL} alt="logo"/>
                         </Link> }
                         {/* <div className="d-block d-xl-none mt-2">
                         <a target="_blank" href={`${this.props.language === 'EN'?
                           'https://www.efepae.gr/files4users/files/%CE%A3%CE%97%CE%9C%CE%91%CE%A4%CE%9F%CE%94%CE%9F%CE%A4%CE%97%CE%A3%CE%97/%CE%B1%CF%86%CE%AF%CF%83%CE%B5%CF%82%CE%BA%CE%B5%CE%B9%CE%BC%CE%B5%CE%BD%CE%BFAkiklosFLEPENDEN.pdf':
                           'https://www.efepae.gr/files4users/files/%CE%A3%CE%97%CE%9C%CE%91%CE%A4%CE%9F%CE%94%CE%9F%CE%A4%CE%97%CE%A3%CE%97/%CE%B1%CF%86%CE%AF%CF%83%CE%B5%CF%82%CE%BA%CE%B5%CE%B9%CE%BC%CE%B5%CE%BD%CE%BFAkiklosFLEPENDGR.pdf'}`}>
                           <img style={{maxWidth:"150px"}} src={`/eu-banner-${this.props.language === 'EN'?'en':'el'}.jpg`} alt="eu-banner"/>
                         </a>
                         </div> */}

                       </div>
                       <div className="col-xl-7 col-lg-6 col-1 text-sm-center">
                         <div className="nav-wrap">

                           {/* main nav start */}
                           <nav className="top-nav">
                             <ul className="nav sf-menu">

                                  {/* example
                                    <li className={this.props.pathname === "/"? 'active' : null}>
                                     <Link to={'/'}><span href="index.html">Home</span></Link>

                                    </li>
                                    */}

                                      {this.props.menu.links.map((item, i) => {
                                                        return (

                                                          <li className={this.props.pathname === item.url.path? 'active' : null} key={i}>
                                     {item.url.path === '/en' ? <Link to='/'><span >{item.label}</span></Link>   : <Link to={item.url.path+'/'}><span >{item.label}</span></Link> }


                                                          </li>
                                                        )
                                                      }) }



                             {this.props.language === 'EN'? <li className={this.props.pathname === '/contact'? 'active' : null} >
                                 <Link to='/en/contact/'><span>Contact</span></Link>
                             </li> :
                             <li className={this.props.pathname === '/contact'? 'active' : null} >
                                 <Link to='/el/contact/'><span>Επικοινωνια</span></Link>
                             </li>
                            }





                             </ul>

                             <div className="d-inline-block d-xl-none flagcontmobile mt-3">
                               <ReactFlagsSelect
                                   countries={["GB", "GR"]}
                                   onSelect={this.onSelectFlag}
                                   defaultCountry={this.props.language === 'EN'? 'GB': 'GR'}


                                   customLabels={{"GB": "English","GR": "Ελληνικά"}}
                                   placeholder="Select Language" />
                             </div>
                           </nav>
                           {/* eof main nav */}

                           {/*hidding includes on small devices. They are duplicated in topline*/}


                         </div>
                       </div>
                       <div className="col-xl-3 col-lg-3 text-lg-left text-xl-right d-none d-xl-flex align-items-center">

                         {/* <a target="_blank" href={`${this.props.language === 'EN'?
                           'https://www.efepae.gr/files4users/files/%CE%A3%CE%97%CE%9C%CE%91%CE%A4%CE%9F%CE%94%CE%9F%CE%A4%CE%97%CE%A3%CE%97/%CE%B1%CF%86%CE%AF%CF%83%CE%B5%CF%82%CE%BA%CE%B5%CE%B9%CE%BC%CE%B5%CE%BD%CE%BFAkiklosFLEPENDEN.pdf':
                           'https://www.efepae.gr/files4users/files/%CE%A3%CE%97%CE%9C%CE%91%CE%A4%CE%9F%CE%94%CE%9F%CE%A4%CE%97%CE%A3%CE%97/%CE%B1%CF%86%CE%AF%CF%83%CE%B5%CF%82%CE%BA%CE%B5%CE%B9%CE%BC%CE%B5%CE%BD%CE%BFAkiklosFLEPENDGR.pdf'}`}>
                           <img style={{maxWidth:"150px"}} src={`/eu-banner-${this.props.language === 'EN'?'en':'el'}.jpg`} alt="eu-banner"/>
                         </a> */}


<ReactFlagsSelect
   countries={["GB", "GR"]}
   onSelect={this.onSelectFlag}
   defaultCountry={this.props.language === 'EN'? 'GB': 'GR'}


   customLabels={{"GB": "English","GR": "Ελληνικά"}}

   placeholder="Select Language" />




                       </div>
                     </div>
                   </div>
                   {/* header toggler */}
                   <span onClick={this.handleClick} className="toggle_menu">
                     <span></span>

                   </span>




                 </header>

       </div>








    )
  }

}

export default props => (

  <StaticQuery

    query={graphql`

      {
        drupal {
     menuByName(name: "main") {
       links {
         label
         url {
           path

         }
       }

     }
     headerlogo: nodeById(id: "40") {
     ... on DRUPAL_NodeLogo {
       fieldImage {
         url
         imageFile {
           publicURL
         }
       }
       title
     }
   }
   }
     }





    `}

    render={data => <Header data={data} {...props} />}

  />

);
